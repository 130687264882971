import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";

const SuccessPage = () => {
  const intl = useIntl();
  return (
    <div className="flex justify-center">
      <div className="bg-secondary max-w-lg mt-5 md:mt-10 mb-20 rounded-md  p-5 mx-3 ">
        <h1 className="text-center text-white text-2xl mt-10">
          {intl.formatMessage({ id: "404.title" })}
        </h1>
        <div className="text-center mt-10 mb-10">
          <Link
            to="/"
            className="bg-white px-6 py-4 mb-10 text-secondary uppercase font-bold"
          >
            {intl.formatMessage({ id: "backButton" })}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
